.whatsapp-chat-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 999;
    background-color: #25D366;
    border-radius: 50%;
    padding: 10px;
    border: none;
    cursor: pointer;
  }
  
  .whatsapp-chat-button img {
    width: 50px;
    height: 50px;
  }
  
  .whatsapp-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1001;
  }
  
  .whatsapp-modal {
    position: relative;
    width: 80%;
    height: 80%;
    background-color: white;
    border-radius: 8px;
    overflow: hidden;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  