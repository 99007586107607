/* 
.cart_main{
  background: url('../assets/login_bg.png');
  background-color: #F4E9DC;
  height: fit-content;
  margin-top: 0;
  padding-top: 5px;
 
}
:root{
color: #723207;
}
.cart_main .prod-head{
display: flex;
justify-content: space-around;
font-size: 20px;
font-weight: bolder;
width: 100%;
}
.cart1 {
  width: 80%;
  background-color: #F4E9DC;
  margin: auto;
  padding: 20px;
  border: 1px solid #651c1c;
  
  
}

.cart1 .cart-item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #651c1c;
  padding: 10px 0;
  justify-content: space-around;
 

}
.cart1 .prod-det{
  display: flex;
  flex-direction: column;
 width: 10%;
  

}

.cart1  .cart-item img {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-right: 20px;
}

.cart1  .cart-item-details {

 width: 80%;
 margin-left: 5%;
 display: flex;
  justify-content: space-around;

}

.cart1  .cart-item-details h3,
.cart-item-details p {
  margin: 5px 0;
}

.cart1  .cart-item-details input {
  width: 50px;
  margin-left: 10px;
}

.cart1  .cart-item-details button {
  padding: 5px 10px;
  background-color: #723207;
  color: white;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  transition: 0.3ms all  ease-in-out;
}

.cart1  .cart-item-details button:hover {
  background-color: #DCC0A9;
  
}


.cart1 .proceed {
  display: flex;
  width: 100%;
  justify-content: space-around;

}
.cart1 .proceed h2 {
  margin-top: 20px;
  float: left;
}

.cart1 .proceed button{
  font-size: 20px;
  background-color: #651c1c;
  outline: none;
  border: none;
  padding: 10px;
  margin-top: 20px;
  color: white;
  
  cursor: pointer;

  float: right;
}
.cart1 .proceed button:hover{
  background-color:#DCC0A9;
}

.cart1 .cart-item-details input[type='number']{
  border: none;
  border-radius: 50px;
  text-align: center;
  box-shadow: 0 0 2px 0;
  
}
@media(max-width:768px) {
  .cart_main .prod-head{
display: none;
}
.cart1 .prod-det{
flex-direction: row;
width: 100%;
}
.cart1 .cart-item-details{
flex-direction: column;
width: 50%;
}
.cart1 .cart-item{
justify-content: space-between;
}
.cart1 .category_cart{
display: none;
}
.cart1 .proceed h2 {
font-size: 16px;
}
.cart1 .proceed button{
font-size: 16px;
margin-top: 16px;
}
.cart1 {
border: none;
}
  
}
@media (max-width: 420px) {
  .cart1 .prod-det {
    flex-direction: column;
  }
  .cart1 .cart-item-details button{
    font-size: 10px;
  }
} */

.cart-item-details h3{
  font-size: 15px;
  font-weight: 500;
}
.cart-drawer {
  position: fixed;
  right: -100%;
  top: 0;
  width: 400px;
  height: 100vh;
  background: white;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-in-out;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  
}

.cart-drawer.open {
  right: 0;

}
.cart-drawer{
  border-bottom-left-radius: 35px;
  border-top-left-radius: 35px;
}
.emptycarticon{
  width: 100%;
}
.emptycartpara{
  text-align: center;
  font-size: 25px;
  background-color: #fff;
  border-radius: 15px;
}

.cart-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 25px 15px;
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
  background-color: #fff5ea !important;
  border-top-left-radius: 35px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.cart-header h2{
  font-size: 20px;
  font-weight: 500;
  margin: 0;
}
.cart-header .itemin{
  color: #6c6c6c;
}

.close-btn {
  background: none;
  border: none;
  cursor: pointer;
}
.checkout-btn:disabled {
  background-color: #b0b0b0; /* Gray color for disabled state */
  color: #fff;
  cursor: not-allowed;
  opacity: 0.8;
}
.cart-content {
  flex: 1;
  padding: 15px;
  overflow-y: auto;
  position: relative;
  margin-bottom: 70px;
}

.milestone {
  text-align: center;
  background: #f5f5f5;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
}

.progress-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.progress {
  flex: 1;
  height: 5px;
  /* background: #ccc; */
  margin: 0 10px;
  position: relative;
}

.indicator {
  position: absolute;
  left: 50%;
  width: 10px;
  height: 10px;
  background: blue;
  border-radius: 50%;
}

.cart-item {
  display: flex;
  align-items: center;
  padding: 10px;
  background: #fffaf3;
  border-radius: 15px;
  margin-bottom: 10px;
}

.cart-item-image {
  width: 20%;
  height: auto;
  margin-right: 10px;
}

.cart-item-details {
  flex-grow: 1;
}

.quantity-controls {
  display: flex;
  align-items: center;
  border: 1px solid #dbdbdb;
  border-radius: 5px;
  background-color: #fff;
}
.quantity-controls span{
  padding: 0 10px;
  font-size: 12px;
}
.quantity-controls .buttons {
  background-color: #e9e9e9;
  width: 25px;
  height: 22px;
  font-size: 15px;
  border: none;
  cursor: pointer;
  padding: 5px;
  align-content: center;
  align-items: center;
  justify-content: center;
}

.remove-btn {
  background: #fff;
  color: #656565;
  border: none;
  font-size: 18px;
  cursor: pointer;
  padding: 0px 4px;
  border-radius: 5px;
}
/* Slide Up Animation */
@keyframes slideUp {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.login-popup-content{
  width: 100%;
  padding: 25px;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  animation: slideUp 0.4s ease-out;
}
.login-popup-content input:focus{
  outline: none;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
}
.login-popup{
  transition: opacity 0.3s ease-in-out;
}
.login-popup-content p{
  text-align: center;
  margin: 0;
}
.login-popup-content input{
  margin: 17px 0px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.loginpopup-closebtn{
  background-color: transparent;
  border: none;
  position: relative;
  top: -15px;
  width: 10%;
  left: 94%;
}
.loginpopup-closebtn svg{
  font-size: 30px;
}
.editnumbersup{
  font-size: 16px;
  cursor: pointer;
}
.login-popup-content .login-btn , .verify-btn{
  background-color: #723207;
  width: 70%;
  text-align: center;
  color: #fff;
  align-self: center;
  border: none;
  padding: 10px;
  border-radius: 10px;
}
.cart-footer {
  padding: 15px 20px;
  position: absolute;
  bottom: 0;
  display: flex;
  width: 100%;
  border-top: 1px solid #e5e5e5;
  background-color: #fffbf2;
  border-bottom-left-radius: 35px;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.cart-content::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.cart-content {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.cart-footer h2{
  font-size: 18px;
  margin-right: 15px;
  display: flex;
  flex-direction: column;
  width: 30%;
}
.cart-footer span{
  font-size: 12px;
  text-decoration: overline;
  font-weight: 300;
}
.checkout-btn {
  width: 70%;
  background: #723207;
  color: white;
  padding: 10px;
  border: none;
  cursor: pointer;
  border-radius: 10px;
  
}

.cart-item-details p span{
  font-size: 10px;
  font-weight: 400;
}
.cart-item-details p {
  font-weight: 500;
}


/* RESPONSIVENESS */

/* Small screens (mobile) */
@media (max-width: 768px) {
  .cart-drawer {
    width: 100% !important;
  }


  .cart-header {
    font-size: 16px;
    padding: 12px;
  }

  .cart-item {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .cart-item-image {
    width: 80px;
    height: 80px;
    margin-bottom: 5px;
  }

  .cart-item-details {
    width: 100%;
  }

  .quantity-controls {
    justify-content: center;
  }

  .checkout-btn {
    font-size: 14px;
    padding: 10px;
  }
}

/* Medium screens (tablets) */
@media (max-width: 1024px) {
  .cart-drawer {
    width: 50%;
  }
}

/* Large screens (desktops) */
@media (min-width: 1200px) {
  .cart-drawer {
    width: 400px;
  }
}
