@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.myorders-main {
    /* Add any global styles here */
}

.my-orders {
    max-width: 1200px;
    margin: 0 auto;
    
    padding: 0 20px;
}

.my-orders .order-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.my-orders .order-card {
    display: flex;
    
    border: 1px solid #ddd;
    border-radius: 5px;
    padding: 20px;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 4px 0;
    transition: transform 0.3s ease-in-out; /* Smooth transition for the transform */
    flex-direction: column;
}
.my-orders .order-card2{
    display: flex;
    flex-wrap: wrap;

}
.pnq{
    display: flex;
    gap: 10px;
}
.my-orders .order_buttons{
    display: flex;
}
.my-orders .block1 h2, .my-orders .block1 a{
    font-size: 20px;
}
.my-orders .product-image {
    width: 100px;
    height: 100px;
    object-fit: cover;
}

.my-orders .block1 h2, .my-orders .block1 a {
    font-family: "Poppins", sans-serif;
    font-weight:  600;
    text-decoration: none;
    color: inherit;
    -webkit-line-clamp: 2;
    /* -webkit-line-clamp: 1; */
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.block2 {
    display: flex;
}

.block1 {
    display: flex;
    flex-direction: column;
}

.my-orders h2 {
    margin: 0 0 10px;
}

.my-orders p {
    margin: 5px 0;
}

.my-orders button {
 
    margin-right: 10px;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #663E18;
    border-radius: 25px;
    color: white;
    cursor: pointer;
    font-size: 14px;
}

.my-orders button:last-child {
    background-color: #A50D0E;
}

.my-orders button:hover {
    opacity: 0.9;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
}

.modal-content {
    background-color: #DCC0A9;
    padding: 20px;
    border-radius: 5px;
    max-width: 600px;
    width: 100%;
    border-radius: 30px 0 0 30px;
    padding: 25px;
}

/* Responsive styles */
@media (max-width: 768px) {
    .my-orders {
        margin-top: 90px;
    }

    .my-orders .order-card {
        flex-direction: column;
        align-items: flex-start;
    }

    .my-orders .block2 {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .my-orders .product-image {
        width: 80px;
        height: 80px;
    }

    .my-orders .block1 {
        width: 100%;
        text-align: center;
    }

    .my-orders button {
        width: 100%;
        margin: 10px 0;
    }



    .my-orders .product-image {
        width: 60px;
        height: 60px;
    }

    .my-orders h2 {
        font-size: 16px;
    }

    .my-orders p {
        font-size: 14px;
    }

    .my-orders button {
        font-size: 12px;
        padding: 8px 16px;
    }
    
        .my-orders .order_buttons {
            width: 100%;
            display: flex;
            gap: 50px;
        }
    
}
