@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


h1,h2,h3,h4,h5{
    font-weight: bold;
  }
  @font-face {
    font-family: 'Poppins';
    src: url('https://fonts.gstatic.com/s/poppins/v20/pxiEyp8kv8JHgFVrJJLmEw.woff2') format('woff2'); 
    font-weight: 400; 
    font-display: swap;
}

@font-face {
    font-family: 'Poppins';
    src: url('https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLDz8Z1xlEA.woff2') format('woff2'); 
    font-weight: 700; 
    font-display: swap;
}