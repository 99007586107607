.bloglistitem img{
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    height: 200px;
    object-fit: cover;
}
.bloglistitem{
    border: 1px solid #d7d7d7;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    padding: 0;
    border-radius: 20px;
}
.blogdesc{
    padding: 5px 15px;
}
.blogdesc p{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}
.blogdesc h3{
    font-size: 20px;
    color: rgb(75, 45, 0);
}
.bloglink{
    text-decoration: none;
    color:#000;
}
.writerdetail{
    display: flex;
    justify-content: space-between;
    margin: 2px 5px;
}
.writerdetail p {
    margin: 0;
    color: #646464
}